import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "../components/scss/app.scss"

const AboutKalotiPage = () => (
    <Layout>
    <SEO title="Smile Stylist Services | Free Consultation | Cosmetic Dentistry Toronto" 
    description="We specialize in cosmetic dentistry services such as porcelain veneers, full mouth restorations, smile makeovers, bonding’s and implants in the Greater Toronto Area." 
    keywords="Smile Consultation" />
    <main className="site-content" role="main">
    <section id="main-top-img" className="services hidden-xs">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 sec-title wow animated fadeInDown">
            <h1>Services</h1>
            <h2>From a routine check up to creating an esthetic smile you will love</h2>
          </div>
        </div>
      </div>
    </section>
    <section id="main-top-img" className="services hidden-sm hidden-md hidden-lg">
      <div className="container">
        <div className="row">
          <p>&nbsp;</p>
        </div>
      </div>
    </section>
    <section id="top-mobile" className="hidden-sm hidden-md hidden-lg">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 sec-title wow animated fadeInDown">
            <h2>Services</h2>
            <h2>From a routine check up to creating an esthetic smile you will love</h2>
          </div>
        </div>
      </div>
    </section>
    <section id="main-white">
      <div className="container">
        <div className="row">
          <div className="col-md-4 col-sm-6 col-xs-12 text-center wow animated zoomIn">
            <div className="service-img">
              <img alt=" In - office Bonding"src={ require("../images/desktop/services/in-office-bonding.jpg" )} />
              <a title="In-office Bonding" tit href="/in-office-bonding"><h3>In-office Bonding</h3></a>
              <p>Composite resin bonding can be a fast, minimally invasive and inexpensive option for the beautiful smile you’re looking for.</p>
              <p><a href="in-office-bonding">Learn more...</a></p>
            </div>
          </div>
          <div className="col-md-4 col-sm-6 col-xs-12 text-center wow animated zoomIn" data-wow-delay="0.3s">
            <div className="service-img">
              <img alt="Full Mouth Reconstruction"src={ require("../images/desktop/services/reconstruction.jpg" )} />
              <a title="Full Mouth Reconstruction" href="/full-mouth-reconstruction"><h3>Full Mouth Reconstruction</h3></a>
              <p>If severe dental problems are causing you discomfort, you may be a good candidate for a full mouth reconstruction.</p>
              <p><a href="full-mouth-reconstruction">Learn more...</a></p>
            </div>
          </div>
          <div className="col-md-4 col-sm-6 col-xs-12 text-center wow animated zoomIn" data-wow-delay="0.6s">
            <div className="service-img">
              <img alt="Porcelain Veneers" src={ require("../images/desktop/services/veneers4.jpg" )} />
              <a title="Porcelain Veneers" href="/porcelain-veneers"><h3>Porcelain Veneers</h3></a>
              <p>Porcelain veneers are thin pieces of porcelain used to recreate the natural look of teeth, while also providing strength and resilience.</p>
              <p><a href="porcelain-veneers">Learn more...</a></p>
            </div>
          </div>
          <div className="col-md-4 col-sm-6 col-xs-12 text-center wow animated zoomIn">
            <div className="service-img">
              <img alt="Teeth Whitening" src={ require("../images/desktop/services/whitening2.jpg" )} />
              <a title="Teeth Whitening" href="/teeth-whitening"><h3>Teeth Whitening</h3></a>
              <p>Cosmetic teeth whitening is a big part of our practice. We get great results for our clients and offer many options.</p>
              <p><a href="teeth-whitening">Learn more...</a></p>
            </div>
          </div>
          <div className="col-md-4 col-sm-6 col-xs-12 text-center wow animated zoomIn" data-wow-delay="0.3s">
            <div className="service-img">
              <img alt="Invisible Braces" src={ require("../images/desktop/services/invisible-braces.jpg" )} />
              <a title="Clear Correct Invisible Braces" href="/invisible-braces"><h3>Invisible Braces</h3></a>
              <p>ClearCorrect is an orthodontic alternative to braces, designed to straighten crooked teeth. A series of custom ClearCorrect aligners.</p>
              <p><a href="invisible-braces">Learn more...</a></p>
            </div>
          </div>
          <div className="col-md-4 col-sm-6 col-xs-12 text-center wow animated zoomIn" data-wow-delay="0.6s">
            <div className="service-img">
              <img alt="Gum Lift Procedures" src={ require("../images/desktop/services/gum-lift.jpg" )} />
              <a title="Gum Lift Procedures" href="/gum-lift-procedures"><h3>Gum Lift Procedures</h3></a>
              <p>A cosmetic dental procedure that raises and sculpts the gum line. This procedure may involve reshaping the tissue and/or underlying bones.</p>
              <p><a href="gum-lift-procedures">Learn more...</a></p>
            </div>
          </div>
          <div className="col-md-4 col-sm-6 col-xs-12 text-center wow animated zoomIn">
            <div className="service-img">
              <img alt="All Ceramic Crowns" src={ require("../images/desktop/services/ceramic-crowns.jpg" )} />
              <a title="All Ceramic Crowns" href="/ceramic-crowns"><h3>All Ceramic Crowns</h3></a>
              <p>The biggest advantage of having porcelain (all-ceramic) dental crowns placed is that they create an exceedingly life-like end result.</p>
              <p><a href="ceramic-crowns">Learn more...</a></p>
            </div>
          </div>
          <div className="col-md-4 col-sm-6 col-xs-12 text-center wow animated zoomIn" data-wow-delay="0.3s">
            <div className="service-img">
              <img alt="White Fillings" src={ require("../images/desktop/services/white-fillings.jpg" )} />
              <a title="White Fillings" href="/white-fillings"><h3>White Fillings</h3></a>
              <p>Tooth-colored resin material used to restore decayed teeth. Composites are also used for cosmetic improvements of the smile.</p>
              <p><a href="white-fillings">Learn more...</a></p>
            </div>
          </div>
          <div className="col-md-4 col-sm-6 col-xs-12 text-center wow animated zoomIn" data-wow-delay="0.6s">
            <div className="service-img">
              <img alt="Gaps Between The Teeth" src={ require("../images/desktop/services/gaps-between.jpg" )} />
              <a title="Gaps Between the Teeth" href="/gaps-between-teeth"><h3>Gaps Between the Teeth</h3></a>
              <p>A mismatch between the size of the jaw bones and the size of the teeth can cause either extra space between teeth or crowding of teeth.</p>
              <p><a href="gaps-beetween-teeth">Learn more...</a></p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
  </Layout>
)

export default AboutKalotiPage